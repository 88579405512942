<template>
  <div class="app-wrap">
    <header-component></header-component>

    <main class="view view--home">
      <abschnitt-hero id="hero"></abschnitt-hero>
      <abschnitt-ueber-mich id="über-mich"></abschnitt-ueber-mich>
      <abschnitt-settings id="settings"></abschnitt-settings>
      <abschnitt-erstgespraech id="erstgespräch"></abschnitt-erstgespraech>
      <abschnitt-schwerpunkte id="schwerpunkte"></abschnitt-schwerpunkte>
      <abschnitt-methode id="methode"></abschnitt-methode>
      <abschnitt-kontakt id="kontakt"></abschnitt-kontakt>
    </main>

    <footer-component></footer-component>
  </div>
</template>

<script>
import store from '@/store'

import AbschnittHero from '@/components/AbschnittHero'
import AbschnittUeberMich from '@/components/AbschnittUeberMich'
import AbschnittSettings from '@/components/AbschnittSettings'
import AbschnittErstgespraech from '@/components/AbschnittErstgespraech'
import AbschnittSchwerpunkte from '@/components/AbschnittSchwerpunkte'
import AbschnittMethode from '@/components/AbschnittMethode'
import AbschnittKontakt from '@/components/AbschnittKontakt'

export default {
  components: {
    AbschnittHero,
    AbschnittUeberMich,
    AbschnittSettings,
    AbschnittErstgespraech,
    AbschnittSchwerpunkte,
    AbschnittMethode,
    AbschnittKontakt,
  },
  props: {
    layout: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {
      parallaxRequestId: undefined,
      parallaxJsRaf: undefined,
      fadeInObserver: undefined,
    }
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch('getContents').then(next)
  },
  mounted() {
    this.fadeIn()
    this.parallaxJS('.parallax-group', '.parallax-item')
  },
  methods: {
    fadeIn() {
      const fadingDistance = window.innerHeight * 0.15
      const fadeInItems = Array.from(document.getElementsByClassName('fade-in'))

      fadeInItems.forEach(item => {
        item.style.opacity = 0
        item.style.transform = `translateY(${fadingDistance}px)`
        item.style.transition =
          'opacity 750ms ease-in-out, transform 750ms cubic-bezier(0, .5, .5, 1)'
      })

      this.fadeInObserver = new IntersectionObserver(
        (entries, fadeInObserver) => {
          entries.forEach(entry => {
            if (!entry.isIntersecting) return
            entry.target.style.opacity = 1
            entry.target.style.transform = 'none'
            fadeInObserver.unobserve(entry.target)
          })
        },
        {
          threshold: 0,
          rootMargin: `0px 0px -${fadingDistance / 4}px 0px`,
        }
      )

      fadeInItems.forEach(item => {
        this.fadeInObserver.observe(item)
      })
    },
    endFadeIn() {
      this.fadeInObserver.disconnect()
    },
    parallaxJS(parallaxGroupSelector, parallaxItemSelector) {
      // create data array
      const prepareForParallax = (parallaxGroupSelector, parallaxItemSelector) => {
        const parallaxGroups = Array.from(document.querySelectorAll(parallaxGroupSelector))

        // some browser apply the overall scroll to html and others to body
        const initialScrollPos = Math.max(
          document.documentElement.scrollTop,
          document.body.scrollTop
        )
        const viewportHeight = window.innerHeight

        const parallaxDataArray = []

        parallaxGroups.forEach(group => {
          const groupBoundingRect = group.getBoundingClientRect()
          const groupHeight = groupBoundingRect.height
          const groupTop = groupBoundingRect.top

          const parallaxItems = Array.from(group.querySelectorAll(parallaxItemSelector))

          parallaxItems.forEach(el => {
            const itemDistance = Number(el.dataset.parallaxDistance)
            if (itemDistance <= -1) return
            // determine translation speed and direction
            const translationSpeed = 1 / (1 + itemDistance) - 1

            const balancedScrollPos =
              initialScrollPos + groupTop + groupHeight / 2 - viewportHeight / 2

            parallaxDataArray.push({
              el,
              translationSpeed,
              balancedScrollPos,
            })
          })
        })

        return parallaxDataArray
      }
      const parallaxDataArray = prepareForParallax(parallaxGroupSelector, parallaxItemSelector)

      // translate items
      const translateItems = parallaxDataArray => {
        const scrollPos = Math.max(document.documentElement.scrollTop, document.body.scrollTop)

        parallaxDataArray.forEach(item => {
          const translateY = (item.balancedScrollPos - scrollPos) * item.translationSpeed
          item.el.style.transform = `translate3d(0,${translateY}px,0)`
        })
      }

      // run on loop
      let then = new Date().getTime()
      const fps = 30
      const interval = 1000 / fps

      const parallaxFPS = time => {
        this.parallaxJsRaf = window.requestAnimationFrame(parallaxFPS)

        const now = new Date().getTime()
        const delta = now - then

        if (delta > interval) {
          then = now - (delta % interval)

          translateItems(parallaxDataArray)
        }
      }
      parallaxFPS(0)
    },
    endParallaxJS() {
      window.cancelAnimationFrame(this.parallaxJsRaf)
    },
  },
  beforeDestroy() {
    this.endFadeIn()
    this.endParallaxJS()
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/_mixins.scss';

.abschnitt {
  padding-top: 8rem;

  @include desktop {
    padding-top: 16rem;
  }

  &--ueber-mich {
    .img {
      margin: 0 auto 3rem;

      @include desktop {
        margin: 0;
      }
    }
  }

  &--settings,
  &--erstgespraech,
  &--methode,
  &--kontakt {
    @include desktop {
      display: grid;
      grid-template-columns: repeat(2, 50%);
    }

    .illu {
      display: grid;
      justify-items: center;
      align-items: center;
      position: relative;
      width: 100%;
      max-width: 24rem;
      margin: 0 auto 4.5rem;
      overflow: hidden;

      @include desktop {
        grid-area: illu;
        justify-self: center;
        align-self: center;
        max-width: none;
        margin: 0;
      }

      &::after {
        display: block;
        content: '';
        padding-bottom: calc(100% / 3 * 2);
      }

      &__ebene {
        grid: 1 / 1;
        position: absolute;
        width: 100%;
      }

      &__link {
        position: absolute;

        &::after {
          content: 'Was hat es mit dem Fuchs auf sich?';
          position: absolute;
          width: 11.5rem;
          padding: 0.5rem 1rem 0.5rem 4.5rem;
          bottom: 100%;
          left: 50%;
          font-size: 0.75rem;
          background: $col-white;
          background: url('@/assets/images/illus/fuchs.png') no-repeat 0.5rem center / 3rem auto,
            $col-white;
          border-radius: 4px;
          box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.25);
          opacity: 0;
          transform: translate(-50%, 20%);
          transition: opacity 375ms ease-in-out, transform 375ms cubic-bezier(0, 0.5, 0.5, 1);
        }

        &:hover::after,
        &:focus::after {
          opacity: 1;
          transform: translate(-50%, 0);
        }

        &--settings {
          &::after {
            transform: translate(-100%, 20%);
          }

          &:hover::after,
          &:focus::after {
            transform: translate(-100%, 0);
          }
        }
      }
    }
  }

  &--schwerpunkte {
    justify-self: center;
  }
}

.article {
  margin-top: 2.25rem;
  line-height: $leading-medium;

  @include desktop {
    grid-area: article;
    align-self: center;
    width: calc(90% - 1rem);
    max-width: 32rem;
    margin-top: 0;
  }
}

.title {
  margin-bottom: 3rem;
  text-align: center;

  @include desktop {
    margin-bottom: 6rem;
    text-align: left;
  }
}

.subtitle {
  margin-bottom: 1.5rem;
}
</style>
