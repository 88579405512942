import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    contents: {},
  },
  getters: {
    ueberMich: state => state.contents.ueberMich,
    settings: state => state.contents.settings,
    settings_aktuell: state => state.contents.settings_aktuell,
    erstgespraech: state => state.contents.erstgespraech,
    schwerpunkte_kinder: state => state.contents.schwerpunkte_kinder,
    schwerpunkte_eltern: state => state.contents.schwerpunkte_eltern,
    schwerpunkte_erwachsene: state => state.contents.schwerpunkte_erwachsene,
    methode: state => state.contents.methode,
    kontakt: state => state.contents.kontakt,
  },
  mutations: {
    setContents: (state, contentsArray) => {
      contentsArray.forEach(({ field, content }) => {
        state.contents[field] = content
      })
    },
  },
  actions: {
    getContents: ({ commit }) => {
      return new Promise((resolve, reject) => {
        const url = `/contents`
        const config = {}
        axios
          .get(url, config)
          .then(response => {
            commit('setContents', response.data.data)
            resolve()
          })
          .catch(err => reject(err))
      })
    },
  },
})
