<template>
  <section class="abschnitt abschnitt--ueber-mich">
    <picture>
      <source
        :srcset="getSrcset(portraitWebpImgs)"
        sizes="(min-width: 768px) 28rem, (min-width: 640px) 20rem, 50vw"
        type="image/webp"
      />
      <source
        :srcset="getSrcset(portraitJpgImgs)"
        sizes="(min-width: 768px) 28rem, (min-width: 640px) 20rem, 50vw"
        type="image/jpeg"
      />
      <img class="img img--portrait fade-in" :src="getSrc(portraitJpgImgs)" alt="Portraitfoto" />
    </picture>

    <article class="article hyphenate fade-in">
      <h2 class="title">Über Mich</h2>

      <div class="cms" v-html="ueberMich"></div>
    </article>
  </section>
</template>

<script>
export default {
  name: 'AbschnittUeberMich',
  data() {
    return {
      portraitWebpImgs: [
        { url: require('@/assets/images/portrait_640.webp'), size: 640 },
        { url: require('@/assets/images/portrait_960.webp'), size: 960 },
      ],
      portraitJpgImgs: [
        { url: require('@/assets/images/portrait_640.jpg'), size: 640 },
        { url: require('@/assets/images/portrait_960.jpg'), size: 960 },
      ],
    }
  },
  computed: {
    ueberMich() {
      return this.$store.getters.ueberMich
    },
  },
  methods: {
    getSrcset(imgs) {
      return imgs.reduce((acc, res) => acc + res.url + ' ' + res.size + 'w,', '')
    },
    getSrc(imgs) {
      return imgs[0].url
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/_mixins.scss';

.abschnitt--ueber-mich {
  @include desktop {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-template-areas:
      'article .'
      'article img';
    grid-template-rows: calc(3.375rem + 6rem) auto;
  }

  @include wider-than(1280px) {
    grid-template-rows: calc(3.75rem + 6rem) auto;
  }

  .img {
    height: 20rem;
    max-height: 50vw;
    object-fit: center;

    @include desktop {
      grid-area: img;
      align-self: start;
      justify-self: center;
      height: 28rem;
    }
  }
}
</style>
