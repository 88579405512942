import Vue from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'

import axios from 'axios'

import '@/assets/scss/app.scss'

import MenuComponent from './components/MenuComponent.vue'
import HeaderComponent from './components/HeaderComponent.vue'
import FooterComponent from './components/FooterComponent.vue'

Vue.config.productionTip = false

axios.defaults.baseURL = process.env.VUE_APP_API_URL

Vue.component('menu-component', MenuComponent)
Vue.component('header-component', HeaderComponent)
Vue.component('footer-component', FooterComponent)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
