<template>
  <section class="abschnitt abschnitt--methode">
    <div class="illu parallax-group fade-in">
      <img
        class="illu__ebene parallax-item"
        :srcset="getSrcset(wieseBergeImgs)"
        :src="getSrc(wieseBergeImgs)"
        sizes="(min-width: 1690px) 720px, (min-width: 768px) 45vw, 24rem"
        alt="Wiesenlandschaft: Berge"
        data-parallax-distance=".1"
      />
      <img
        class="illu__ebene parallax-item"
        :srcset="getSrcset(wieseSeeImgs)"
        :src="getSrc(wieseSeeImgs)"
        sizes="(min-width: 1690px) 720px, (min-width: 768px) 45vw, 24rem"
        alt="Wiesenlandschaft: See"
        data-parallax-distance=".075"
      />
      <img
        class="illu__ebene parallax-item"
        :srcset="getSrcset(wieseSesselImgs)"
        :src="getSrc(wieseSesselImgs)"
        sizes="(min-width: 1690px) 720px, (min-width: 768px) 45vw, 24rem"
        alt="Wiesenlandschaft: Sessel"
        data-parallax-distance="-.025"
      />
      <router-link
        class="illu__link illu__link--methode parallax-item"
        :to="{ name: 'der-fuchs' }"
        data-parallax-distance="-.025"
      ></router-link>
    </div>

    <article class="article fade-in">
      <h2 class="title">Methode</h2>

      <div class="cms" v-html="methode"></div>
    </article>
  </section>
</template>

<script>
export default {
  name: 'AbschnittMethode',
  data() {
    return {
      wieseBergeImgs: [
        { url: require('@/assets/images/illus/wiese_berge_960.png'), size: 960 },
        { url: require('@/assets/images/illus/wiese_berge_1280.png'), size: 1280 },
        { url: require('@/assets/images/illus/wiese_berge_1920.png'), size: 1920 },
      ],
      wieseSeeImgs: [
        { url: require('@/assets/images/illus/wiese_see_960.png'), size: 960 },
        { url: require('@/assets/images/illus/wiese_see_1280.png'), size: 1280 },
        { url: require('@/assets/images/illus/wiese_see_1920.png'), size: 1920 },
      ],
      wieseSesselImgs: [
        { url: require('@/assets/images/illus/wiese_sessel_960.png'), size: 960 },
        { url: require('@/assets/images/illus/wiese_sessel_1280.png'), size: 1280 },
        { url: require('@/assets/images/illus/wiese_sessel_1920.png'), size: 1920 },
      ],
    }
  },
  computed: {
    methode() {
      return this.$store.getters.methode
    },
  },
  methods: {
    getSrcset(imgs) {
      return imgs.reduce((acc, res) => acc + res.url + ' ' + res.size + 'w,', '')
    },
    getSrc(imgs) {
      return imgs[0].url
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/_mixins.scss';

.abschnitt--methode {
  @include desktop {
    grid-template-areas: 'article illu';
  }

  .illu__link--methode {
    top: 59%;
    left: 41%;
    height: 19%;
    width: 15%;
  }
}
</style>
