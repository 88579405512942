<template>
  <section class="abschnitt abschnitt--erstgespraech">
    <div class="illu parallax-group fade-in">
      <img
        class="illu__ebene parallax-item"
        :srcset="getSrcset(wuesteDuenenImgs)"
        :src="getSrc(wuesteDuenenImgs)"
        sizes="(min-width: 1690px) 720px, (min-width: 768px) 45vw, 24rem"
        alt="Wüstenlandschaft: Dünen"
        data-parallax-distance=".1"
      />
      <img
        class="illu__ebene parallax-item"
        :srcset="getSrcset(wuesteOaseImgs)"
        :src="getSrc(wuesteOaseImgs)"
        sizes="(min-width: 1690px) 720px, (min-width: 768px) 45vw, 24rem"
        alt="Wüstenlandschaft: Oase"
        data-parallax-distance=".05"
      />
      <img
        class="illu__ebene parallax-item"
        :srcset="getSrcset(wuesteSesselImgs)"
        :src="getSrc(wuesteSesselImgs)"
        sizes="(min-width: 1690px) 720px, (min-width: 768px) 45vw, 24rem"
        alt="Wüstenlandschaft: Sessel"
        data-parallax-distance="-.025"
      />
      <router-link
        class="illu__link illu__link--erstgespraech parallax-item"
        :to="{ name: 'der-fuchs' }"
        data-parallax-distance=".05"
      ></router-link>
    </div>

    <article class="article fade-in">
      <h2 class="title">Erstgespräch</h2>

      <div class="cms" v-html="erstgespraech"></div>

      <button class="cta" @click="scrollTo('kontakt', true)">Kontakt</button>
    </article>
  </section>
</template>

<script>
export default {
  name: 'AbschnittErstgespraech',
  data() {
    return {
      wuesteDuenenImgs: [
        { url: require('@/assets/images/illus/wueste_duenen_960.png'), size: 960 },
        { url: require('@/assets/images/illus/wueste_duenen_1280.png'), size: 1280 },
        { url: require('@/assets/images/illus/wueste_duenen_1920.png'), size: 1920 },
      ],
      wuesteOaseImgs: [
        { url: require('@/assets/images/illus/wueste_oase_960.png'), size: 960 },
        { url: require('@/assets/images/illus/wueste_oase_1280.png'), size: 1280 },
        { url: require('@/assets/images/illus/wueste_oase_1920.png'), size: 1920 },
      ],
      wuesteSesselImgs: [
        { url: require('@/assets/images/illus/wueste_sessel_960.png'), size: 960 },
        { url: require('@/assets/images/illus/wueste_sessel_1280.png'), size: 1280 },
        { url: require('@/assets/images/illus/wueste_sessel_1920.png'), size: 1920 },
      ],
    }
  },
  computed: {
    erstgespraech() {
      return this.$store.getters.erstgespraech
    },
    srcsetHero() {
      return this.heroImgs.reduce((acc, res) => acc + res.url + ' ' + res.size + 'w,', '')
    },
  },
  methods: {
    scrollTo(target) {
      const targetEl = document.getElementById(target)
      targetEl.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
    },
    getSrcset(imgs) {
      return imgs.reduce((acc, res) => acc + res.url + ' ' + res.size + 'w,', '')
    },
    getSrc(imgs) {
      return imgs[0].url
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/_mixins.scss';

.abschnitt--erstgespraech {
  @include desktop {
    grid-template-areas: 'article illu';
  }

  .cta {
    position: relative;
    margin-top: 4rem;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: $font-weight-title;
    line-height: 1;
    letter-spacing: $tracking-widest;
    color: $col-white;
    background: $col-blau;
    text-transform: uppercase;
    transform-origin: left;
    transition: transform 250ms cubic-bezier(0.5, 0, 0.5, 1);
    z-index: 1;
    overflow: hidden;
    border-radius: 4px;

    &::after {
      content: '';
      position: absolute;
      width: 105%;
      padding-bottom: 105%;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      background-color: $col-hellblau;
      z-index: -1;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 250ms cubic-bezier(0.3, 0, 0.3, 1);
    }

    &:hover::after,
    &:focus::after {
      transform: translate(-50%, -50%) scale(1);
    }
  }

  .illu__link--erstgespraech {
    top: 60%;
    left: 38%;
    height: 15%;
    width: 19%;
  }
}
</style>
