<template>
  <svg viewBox="0 0 1037.09 406.35">
    <title>Sophie Kari</title>
    <text class="text" x="50%" text-anchor="middle" transform="translate(0 379.59)">
      SOPHIE KARI
    </text>
  </svg>
</template>

<script>
export default {
  name: 'LogoText',
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/_mixins.scss';

svg {
  display: block;
  height: 100%;
}

.text {
  font-size: 94px;
  font-family: $font-sans;
  font-weight: $font-weight-text;
  letter-spacing: 0.2em;
}
</style>
