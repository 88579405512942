<template>
  <section class="abschnitt abschnitt--settings">
    <div class="illu parallax-group fade-in">
      <img
        class="illu__ebene parallax-item"
        :srcset="getSrcset(meerMeerImgs)"
        :src="getSrc(meerMeerImgs)"
        sizes="(min-width: 1690px) 720px, (min-width: 768px) 45vw, 24rem"
        alt="Meereslandschaft: Meer"
        data-parallax-distance=".1"
      />
      <img
        class="illu__ebene parallax-item"
        :srcset="getSrcset(meerLeuchtturmImgs)"
        :src="getSrc(meerLeuchtturmImgs)"
        sizes="(min-width: 1690px) 720px, (min-width: 768px) 45vw, 24rem"
        alt="Meereslandschaft: Leuchtturm"
        data-parallax-distance=".05"
      />
      <img
        class="illu__ebene parallax-item"
        :srcset="getSrcset(meerSesselImgs)"
        :src="getSrc(meerSesselImgs)"
        sizes="(min-width: 1690px) 720px, (min-width: 768px) 45vw, 24rem"
        alt="Meereslandschaft: Sessel"
        data-parallax-distance="-.025"
      />
      <router-link
        class="illu__link illu__link--settings parallax-item"
        :to="{ name: 'der-fuchs' }"
        data-parallax-distance=".05"
      ></router-link>
    </div>

    <article class="article fade-in">
      <h2 class="title">Settings</h2>

      <div class="cms" v-html="settings"></div>

      <div class="aktuell">
        <h3 class="subtitle">Aktuell</h3>

        <div class="cms" v-html="settings_aktuell"></div>
      </div>
    </article>
  </section>
</template>

<script>
export default {
  name: 'AbschnittSettings',
  data() {
    return {
      meerMeerImgs: [
        { url: require('@/assets/images/illus/meer_meer_960.png'), size: 960 },
        { url: require('@/assets/images/illus/meer_meer_1280.png'), size: 1280 },
        { url: require('@/assets/images/illus/meer_meer_1920.png'), size: 1920 },
      ],
      meerLeuchtturmImgs: [
        { url: require('@/assets/images/illus/meer_leuchtturm_960.png'), size: 960 },
        { url: require('@/assets/images/illus/meer_leuchtturm_1280.png'), size: 1280 },
        { url: require('@/assets/images/illus/meer_leuchtturm_1920.png'), size: 1920 },
      ],
      meerSesselImgs: [
        { url: require('@/assets/images/illus/meer_sessel_960.png'), size: 960 },
        { url: require('@/assets/images/illus/meer_sessel_1280.png'), size: 1280 },
        { url: require('@/assets/images/illus/meer_sessel_1920.png'), size: 1920 },
      ],
    }
  },
  computed: {
    settings() {
      return this.$store.getters.settings
    },
    settings_aktuell() {
      return this.$store.getters.settings_aktuell
    },
  },
  methods: {
    getSrcset(imgs) {
      return imgs.reduce((acc, res) => acc + res.url + ' ' + res.size + 'w,', '')
    },
    getSrc(imgs) {
      return imgs[0].url
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/_mixins.scss';

.abschnitt--settings {
  @include desktop {
    grid-template-areas: 'illu article';

    .article {
      justify-self: end;
    }
  }

  .aktuell {
    margin: 2rem 0 0;
    padding: 1.5rem 0 2rem;

    @include desktop {
      margin: 4rem 0 0;
    }

    .subtitle {
      position: relative;
      margin-left: 3rem;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: -3rem;
        width: 2rem;
        height: 2px;
        transform: translateY(-50%);
        background: $col-black;
      }
    }

    h4 {
      margin-bottom: 1.125rem;
    }
  }

  .illu__link--settings {
    top: 81%;
    left: 93%;
    height: 12%;
    width: 9%;
  }
}
</style>
