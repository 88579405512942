class Layout {
  constructor(thresholdWidth) {
    this.thresholdWidth = thresholdWidth
  }

  get width() {
    return document.body.offsetWidth
  }

  get height() {
    return document.body.offsetHeight
  }

  get ratio() {
    return this.width / this.height
  }

  get device() {
    return this.width > this.thresholdWidth ? 'desktop' : 'mobile'
  }

  get isMobile() {
    return this.device === 'mobile'
  }

  get isDesktop() {
    return this.device === 'desktop'
  }

  get pixelRatio() {
    return window.devicePixelRatio
  }
}

export default Layout
