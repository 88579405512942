<template>
  <svg viewBox="0 0 1037.09 406.35">
    <title>Logo: Sophie Kari</title>
    <path
      d="M430,119.66h0a103,103,0,0,0,158.75,45.82,103,103,0,0,0-111.61-65.7,103.39,103.39,0,0,1,42.09-38.93h0a13.89,13.89,0,0,0,7.2-12.5,102.56,102.56,0,0,1-26.63-3.48,102,102,0,0,1-21.63-8.52A104,104,0,0,0,479.62,19c0-1.93-.06-3.85-.16-5.75a2.07,2.07,0,0,0-3.29-1.54c-2.63,1.92-5.16,4-7.59,6.14q.5-5,.5-10.2c0-1.93-.05-3.84-.16-5.74A2.07,2.07,0,0,0,465.63.4a103.74,103.74,0,0,0-15.57,14A103.11,103.11,0,0,0,430,119.66M460.29,153q0,3.47.23,7a100.9,100.9,0,0,1-28.28-40.18,101,101,0,0,1,77.7-56A105,105,0,0,0,460.29,153Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'LogoImage',
}
</script>

<style lang="scss" scoped>
svg {
  display: block;
  height: 100%;
}
</style>
