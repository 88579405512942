var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-wrap"},[_c('header-component'),_c('main',{staticClass:"view view--der-fuchs"},[_c('h2',{staticClass:"page-heading"},[_vm._v("Der Fuchs")]),_vm._m(0),_c('article',{staticClass:"exzerpt"},[_c('img',{staticClass:"fuchs",attrs:{"src":require('@/assets/images/illus/fuchs.png'),"alt":"Fuchs"}}),_c('p',[_vm._v("In diesem Moment tauchte der Fuchs auf.")]),_vm._m(1),_vm._m(2),_c('p'),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15),_vm._m(16),_vm._m(17),_vm._m(18),_c('p',[_vm._v("Der Fuchs schien sehr verdutzt:")]),_vm._m(19),_vm._m(20),_vm._m(21),_vm._m(22),_vm._m(23),_vm._m(24),_vm._m(25),_c('p',[_vm._v("Aber der Fuchs fing wieder von seiner Idee an:")]),_vm._m(26),_c('p',[_vm._v("Der Fuchs schwieg und schaute den kleinen Prinzen lange an:")]),_vm._m(27),_vm._m(28),_vm._m(29),_vm._m(30),_vm._m(31),_c('p',[_vm._v("Am nächsten Morgen kehrte der Prinz zurück.")]),_vm._m(32),_vm._m(33),_vm._m(34),_c('p',[_vm._v("So zähmte der kleine Prinz den Fuchs. Und als die Stunde des Abschieds nahte:")]),_vm._m(35),_vm._m(36),_vm._m(37),_vm._m(38),_vm._m(39),_vm._m(40),_vm._m(41),_vm._m(42),_c('p',[_vm._v("Der kleine Prinz ging sich noch einmal die Rosen ansehen.")]),_vm._m(43),_c('p',[_vm._v("Und die Rosen waren sehr verlegen.")]),_vm._m(44),_c('p',[_vm._v("Und er wandte sich erneut an den Fuchs:")]),_vm._m(45),_vm._m(46),_vm._m(47),_vm._m(48),_vm._m(49),_vm._m(50),_vm._m(51),_c('img',{staticClass:"fuchs",attrs:{"src":require('@/assets/images/illus/fuchs.png'),"alt":"Fuchs"}})])]),_c('footer-component')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"intro hyphenate"},[_c('p',[_vm._v(" Der Fuchs, der Ihnen auf meiner Homepage begegnet, ist eine Hommage an die Figur des Fuchses aus dem Roman „Der kleine Prinz“ von Antoine de Saint-Exupéry. Denn wie in dieser Parabel geht es auch in der Psychotherapie oft um Beziehung – zu sich selbst und zu anderen Menschen – und die Möglichkeit diese selbstbestimmt und positiv zu gestalten. Wenn der Fuchs vom „Zähmen“ spricht, dann meint er nichts anderes, als sich vertraut machen, sich annähern, sich kennen lernen. Dafür braucht es Zeit, Verlässlichkeit und Vertrauen. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("„Guten Tag“")]),_vm._v(" , sagte der Fuchs. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("„Guten Tag“")]),_vm._v(" , antwortete der kleine Prinz höflich, er drehte sich um, aber sah nichts. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("„Hier bin ich“")]),_vm._v(" , sagte die Stimme, "),_c('span',[_vm._v("„unter dem Apfelbaum.“")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("„Wer bist du?“")]),_vm._v(" , sagte der kleine Prinz. "),_c('span',[_vm._v("„Du bist sehr hübsch ...“")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("„Ich bin ein Fuchs“")]),_vm._v(" , sagte der Fuchs. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("„Komm spiel mit mir“")]),_vm._v(" , schlug der kleine Prinz vor. "),_c('span',[_vm._v("„Ich bin so traurig ...“")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("„Ich kann nicht mit dir spielen“")]),_vm._v(" , sagte der Fuchs. "),_c('span',[_vm._v("„Ich bin nicht gezähmt.“")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("„Ah, Verzeihung“")]),_vm._v(" , sagte der kleine Prinz. Er überlegte und fügte an: "),_c('span',[_vm._v("„Was bedeutet ‚zähmen‘?“")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("„Du bist nicht von hier“")]),_vm._v(" , sagte der Fuchs, "),_c('span',[_vm._v("„was suchst du?“")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("„Ich suche die Menschen“")]),_vm._v(" , sagte der kleine Prinz. "),_c('span',[_vm._v("„Was bedeutet ‚zähmen‘?“")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("„Die Menschen“")]),_vm._v(" , sagte der Fuchs, "),_c('span',[_vm._v(" „sie haben Gewehre und sie jagen. Das ist ungeheuer lästig! Sie züchten auch Hühner. Nur das interessiert sie. Suchst du Hühner?“ ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("„Nein“")]),_vm._v(" , sagte der kleine Prinz. "),_c('span',[_vm._v("„Ich suche Freunde. Was bedeutet ‚zähmen‘?“")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("„Das ist eine fast vergessene Sache“")]),_vm._v(" , sagte der Fuchs. "),_c('span',[_vm._v("„Es bedeutet: ‚sich vertraut machen‘.“")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("„Vertraut machen?“")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("„Natürlich“")]),_vm._v(" , sagte der Fuchs. "),_c('span',[_vm._v(" „Für mich bist du nur ein kleiner Junge, der aussieht wie tausend andere kleine Jungen. Und ich brauche dich nicht. Und du brauchst mich auch nicht. Ich bin für dich nur ein Fuchs, der aussieht wie hunderttausend andere Füchse. Aber wenn du mich zähmst, brauchen wir einander. Du wirst für mich auf der ganzen Welt einmalig sein. Ich werde für dich auf der ganzen Welt einmalig sein ...“ ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("„Ich fange an, zu begreifen“")]),_vm._v(" , sagte der kleine Prinz. "),_c('span',[_vm._v("„Es gibt eine Blume ... ich glaube, sie hat mich gezähmt ...“")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("„Das ist möglich“")]),_vm._v(" , sagte der Fuchs. "),_c('span',[_vm._v("„Auf der Erde sieht man alle möglichen Dinge ...“")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("„Oh! Das ist nicht auf der Erde“")]),_vm._v(" , sagte der kleine Prinz. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("„Auf einem anderen Planeten?“")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("„Ja.“")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("„Gibt es Jäger auf diesem Planeten?“")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("„Nein.“")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("„Das ist interessant! Und Hühner?“")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("„Nein.“")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("„Nichts ist perfekt“")]),_vm._v(" , seufzte der Fuchs. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v(" „Mein Leben ist eintönig. Ich jage Hühner, die Menschen jagen mich. Alle Hühner ähneln sich und alle Menschen ähneln sich. Ich langweile mich ein wenig. Aber wenn du mich zähmst, wird mein Leben mit Heiterkeit erfüllt sein. Ich werde den Klang deines Schrittes kennen, der sich von allen anderen unterscheidet. Die anderen Schritte werden mich unter die Erde jagen. Dein Schritt wird mich aus dem Bau locken wie Musik. Und schau! Siehst du die Weizenfelder dort? Ich esse kein Brot. Weizen ist für mich unnütz. Weizenfelder erinnern mich an rein gar nichts. Und das ist traurig! Aber du hast goldene Haare. Wundervoll wird das sein, wenn du mich erst gezähmt hast! Der goldene Weizen wird mich an dich erinnern. Und ich werde es lieben, das Rauschen des Windes im Getreide ...“ ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("„Bitte ... zähme mich!“")]),_vm._v(" , sagte er. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("„Das möchte ich gern“")]),_vm._v(" , antwortete der kleine Prinz, "),_c('span',[_vm._v(" „aber ich habe nicht viel Zeit. Ich muss Freunde finden und viele Dinge kennenlernen.“ ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("„Man kennt nur die Dinge, die man zähmt“")]),_vm._v(" , sagte der Fuchs. "),_c('span',[_vm._v(" „Die Menschen haben keine Zeit mehr, um etwas kennenzulernen. Sie kaufen die schon fertigen Dinge im Laden. Aber weil es keine Läden für Freunde gibt, haben die Menschen keine Freunde mehr. Wenn du einen Freund willst, dann zähme mich.“ ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("„Was muss ich tun?“")]),_vm._v(" , sagte der kleine Prinz. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("„Man muss Geduld haben“")]),_vm._v(" , antwortete der Fuchs. "),_c('span',[_vm._v(" „Zuerst setzt du dich ein Stück weit entfernt von mir ins Gras. Ich werde dich aus dem Augenwinkel anschauen und du wirst nichts sagen. Die Sprache sorgt für Missverständnisse. Aber jeden Tag wirst du ein bisschen näher rücken können ...“ ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v(" „Es wäre besser gewesen, Du wärest um die gleiche Zeit zurückgekommen“ ")]),_vm._v(" , sagte der Fuchs. "),_c('span',[_vm._v(" „Wenn du zum Beispiel um vier Uhr nachmittags kommst, werde ich ab drei Uhr damit beginnen, glücklich zu sein. Umso später es ist, umso glücklicher werde ich mich fühlen. Um vier Uhr bereits werde ich aufgeregt sein und mir Sorgen machen, ich werde den Preis des Glückes entdecken. Kommst du aber immer irgendwann, werde ich nie wissen, wann ich mein Herz bereithalten soll ... Es muss feste Bräuche geben.“ ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("„Was ist ein Brauch?“")]),_vm._v(" , sagte der kleine Prinz. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("„Auch das wird viel zu oft vergessen“")]),_vm._v(" , sagte der Fuchs. "),_c('span',[_vm._v(" „Es sorgt dafür, dass ein Tag anders ist, als die anderen, eine Stunde anders, als die anderen Stunden. Bei meinen Jägern zum Beispiel gibt es einen Brauch. Donnerstags tanzen sie mit den Mädchen aus dem Dorf. Also ist Donnerstag ein wundervoller Tag! Ich spaziere bis zum Weinberg hinauf. Wenn die Jäger irgendwann einmal tanzen würden, sähen die Tage alle gleich aus und ich hätte niemals Ferien.“ ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("„Ach!“")]),_vm._v(" sagte der Fuchs, "),_c('span',[_vm._v("„ich werde weinen.“")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("„Das ist deine Schuld“")]),_vm._v(" , sagte der kleine Prinz, "),_c('span',[_vm._v(" „ich wünschte dir keineswegs etwas Schlechtes, aber du wolltest, dass ich dich zähme ...“ ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("„Natürlich“")]),_vm._v(" , sagte der Fuchs. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("„Aber du wirst weinen!“")]),_vm._v(" , sagte der kleine Prinz. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("„Natürlich“")]),_vm._v(" , sagte der Fuchs. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("„Also hast du nichts gewonnen!“")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("„Ich habe etwas gewonnen“")]),_vm._v(" , sagte der Fuchs, "),_c('span',[_vm._v("„wegen der Farbe des Weizens.“")]),_vm._v(" Dann fügte er hinzu: ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v(" „Schau dir noch mal die Rosen an. Du wirst begreifen, dass deine einmalig auf der Welt ist. Du wirst wiederkommen, um mir Adieu zu sagen und ich werde dir ein Geheimnis schenken.“ ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v(" „Ihr seht meiner Rose überhaupt nicht ähnlich, ihr taugt rein gar nichts“ ")]),_vm._v(" , sagte er ihnen. "),_c('span',[_vm._v(" „Niemand hat euch gezähmt und ihr habt niemanden gezähmt. Ihr seid wie mein Fuchs. Er war nur ein Fuchs wie hunderttausend andere. Aber ich habe aus ihm meinen Freund gemacht und nun ist er einmalig auf der ganzen Welt.“ ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("„Ihr seid schön, aber ihr seid leer“")]),_vm._v(" , sagte er ihnen noch. "),_c('span',[_vm._v(" „Man kann für euch nicht sterben. Natürlich, könnte Irgendwer im Vorbeilaufen glauben, dass meine Rose euch ähnlich sieht. Aber sie selbst kann sich viel wichtiger nehmen als ihr alle, weil sie es ist, die ich gegossen habe. Weil sie es ist, die ich unter die Glasglocke gestellt habe. Weil sie es ist, die ich mit dem Wandschirm geschützt habe. Weil sie es ist, deren Raupen ich aufgelesen habe (bis auf die zwei oder drei der Schmetterlinge wegen). Weil sie es ist, die ich klagen hörte oder prahlen oder manchmal sogar schweigen. Weil es meine Rose ist.“ ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("„Adieu“")]),_vm._v(" , sagte er. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("„Adieu“")]),_vm._v(" , sagte der Fuchs. "),_c('span',[_vm._v(" „Hier mein Geheimnis. Es ist sehr einfach: Man sieht nur mit dem Herzen. Das Wesentliche ist für die Augen unsichtbar.“ ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("„Das Wesentliche ist für die Augen unsichtbar“")]),_vm._v(" , wiederholte der kleine Prinz, um sich daran zu erinnern. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v(" „Es ist die Zeit, die du für deine Rose geopfert hast, die sie so bedeutsam macht.“ ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v(" „Es ist die Zeit, die ich für meine Rose geopfert habe, die sie so bedeutsam macht“ ")]),_vm._v(" ... sagte der kleine Prinz, um sich daran zu erinnern. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("„Die Menschen haben diese Wahrheit vergessen“")]),_vm._v(" , sagte der Fuchs. "),_c('span',[_vm._v(" „Aber du sollst sie nicht vergessen. Du wirst immer verantwortlich sein für das, was du zähmst. Du bist verantwortlich für deine Rose ...“ ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("„Ich bin verantwortlich für meine Rose“")]),_vm._v(" , wiederholte der kleine Prinz, um sich daran zu erinnern. ")])
}]

export { render, staticRenderFns }