<template>
  <section class="abschnitt abschnitt--kontakt">
    <div class="illu parallax-group fade-in">
      <img
        class="illu__ebene parallax-item"
        :srcset="getSrcset(stadtHaeuserImgs)"
        :src="getSrc(stadtHaeuserImgs)"
        sizes="(min-width: 1690px) 720px, (min-width: 768px) 45vw, 24rem"
        alt="Stadt: Häuser"
        data-parallax-distance=".05"
      />
      <img
        class="illu__ebene parallax-item"
        :srcset="getSrcset(stadtBaumImgs)"
        :src="getSrc(stadtBaumImgs)"
        sizes="(min-width: 1690px) 720px, (min-width: 768px) 45vw, 24rem"
        alt="Stadt: Baum"
        data-parallax-distance="0"
      />
      <img
        class="illu__ebene parallax-item"
        :srcset="getSrcset(stadtSesselImgs)"
        :src="getSrc(stadtSesselImgs)"
        sizes="(min-width: 1690px) 720px, (min-width: 768px) 45vw, 24rem"
        alt="Stadt: Sessel"
        data-parallax-distance="-.025"
      />
      <router-link
        class="illu__link illu__link--kontakt parallax-item"
        :to="{ name: 'der-fuchs' }"
        data-parallax-distance="-.025"
      ></router-link>
    </div>

    <article class="article fade-in">
      <h2 class="title">Kontakt</h2>

      <div class="kontakt-daten">
        <p>Sophie Kari</p>
        <p>0650 310 13 43</p>
        <p>praxis@sophiekari.at</p>
        <p><br /></p>
        <p>Wiedner Hauptstraße 33/11</p>
        <p>1040 Wien</p>
        <p><br /></p>
        <div class="cms" v-html="kontakt"></div>
      </div>
    </article>

    <div class="praxis fade-in">
      <div class="map">
        <picture>
          <source
            :srcset="getSrcset(lageplanWebpImgs)"
            sizes="(min-width: 1694px) 648px, (min-width: 960px) 38.25vw, (min-width: 564px) 480px, 85vw"
            type="image/webp"
          />
          <source
            :srcset="getSrcset(lageplanJpgImgs)"
            sizes="(min-width: 1694px) 648px, (min-width: 960px) 38.25vw, (min-width: 564px) 480px, 85vw"
            type="image/jpeg"
          />
          <img
            class="map__img"
            :src="getSrc(lageplanJpgImgs)"
            alt="Lageplan: Mariahilfer Straße 117/2/21, 1060 Wien"
          />
        </picture>
        <a
          class="map__link"
          href="https://www.google.com/maps/place/Wiedner+Hauptstraße+33,+1040+Wien"
          target="_blank"
        >
          In Google Maps ansehen
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AbschnittKontakt',
  data() {
    return {
      stadtHaeuserImgs: [
        { url: require('@/assets/images/illus/stadt_haeuser_960.png'), size: 960 },
        { url: require('@/assets/images/illus/stadt_haeuser_1280.png'), size: 1280 },
        { url: require('@/assets/images/illus/stadt_haeuser_1920.png'), size: 1920 },
      ],
      stadtBaumImgs: [
        { url: require('@/assets/images/illus/stadt_baum_960.png'), size: 960 },
        { url: require('@/assets/images/illus/stadt_baum_1280.png'), size: 1280 },
        { url: require('@/assets/images/illus/stadt_baum_1920.png'), size: 1920 },
      ],
      stadtSesselImgs: [
        { url: require('@/assets/images/illus/stadt_sessel_960.png'), size: 960 },
        { url: require('@/assets/images/illus/stadt_sessel_1280.png'), size: 1280 },
        { url: require('@/assets/images/illus/stadt_sessel_1920.png'), size: 1920 },
      ],
      lageplanWebpImgs: [
        { url: require('@/assets/images/lageplan_wiedner_hauptstrasse_640.webp'), size: 640 },
        { url: require('@/assets/images/lageplan_wiedner_hauptstrasse_800.webp'), size: 800 },
        { url: require('@/assets/images/lageplan_wiedner_hauptstrasse_960.webp'), size: 960 },
        { url: require('@/assets/images/lageplan_wiedner_hauptstrasse_1280.webp'), size: 1280 },
      ],
      lageplanJpgImgs: [
        { url: require('@/assets/images/lageplan_wiedner_hauptstrasse_640.jpg'), size: 640 },
        { url: require('@/assets/images/lageplan_wiedner_hauptstrasse_800.jpg'), size: 800 },
        { url: require('@/assets/images/lageplan_wiedner_hauptstrasse_960.jpg'), size: 960 },
        { url: require('@/assets/images/lageplan_wiedner_hauptstrasse_1280.jpg'), size: 1280 },
      ],
    }
  },
  computed: {
    kontakt() {
      return this.$store.getters.kontakt
    },
  },
  methods: {
    getSrcset(imgs) {
      return imgs.reduce((acc, res) => acc + res.url + ' ' + res.size + 'w,', '')
    },
    getSrc(imgs) {
      return imgs[0].url
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/_mixins.scss';

.abschnitt--kontakt {
  @include desktop {
    grid-template-areas:
      'illu article'
      'praxis praxis';
    row-gap: 4rem;

    .article {
      justify-self: end;
    }
  }

  .article,
  .praxis {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;

    @include desktop {
      max-width: none;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .article {
    @include desktop {
      width: calc(90% - 1rem);
      max-width: 32rem;
    }
  }

  .praxis {
    margin-top: 4rem;

    .address {
      line-height: $leading-medium;
      text-align: center;
    }

    .map {
      position: relative;
      margin-top: 2rem;
      padding-bottom: 75%;
      overflow: hidden;

      &__img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &__link {
        display: block;
        position: absolute;
        bottom: 1rem;
        left: 1rem;
        padding: 1rem 1rem 1rem 4rem;
        border-radius: 0.5rem;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.125);
        background: $col-white;
        background: url('@/assets/images/google_maps.svg') no-repeat 1rem center / 1.5rem auto,
          $col-white;
      }

      @include desktop {
        &__link {
          width: 14.75rem;
          line-height: $leading-small;
          top: 50%;
          left: 50%;
          bottom: auto;
          padding: 1rem 2rem 1rem 5rem;
          transform: translate(-50%, -50%);
          box-shadow: 0 0 0 100vmax rgba(0, 0, 0, 0.25);
          background: url('@/assets/images/google_maps.svg') no-repeat 1.5rem center / 2rem auto,
            $col-white;
          opacity: 0;
          transition: opacity 250ms ease-in-out;
        }

        &:hover,
        &:focus {
          .map__link {
            opacity: 1;
          }
        }
      }
    }

    @include desktop {
      width: 100%;
      max-width: 720px;
      grid-area: praxis;
      justify-self: center;

      .map {
        padding-bottom: 67.5%;
      }
    }
  }

  .illu__link--kontakt {
    top: 55%;
    left: 25%;
    height: 19%;
    width: 24%;
  }
}
</style>
