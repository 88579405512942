<template>
  <div class="menu">
    <nav class="nav" :class="{ active: menuActive }">
      <div class="nav__illu">
        <img :srcset="getSrcset(menuImgs)" :src="getSrc(menuImgs)" sizes="32vw" alt="Bilderwand" />
      </div>

      <ul class="nav__list">
        <li class="nav__item">
          <a href="#" class="nav__link" @click.prevent="goTo('über-mich')">Über Mich</a>
        </li>
        <li class="nav__item">
          <a href="#" class="nav__link" @click.prevent="goTo('settings')">Settings</a>
        </li>
        <li class="nav__item">
          <a href="#" class="nav__link" @click.prevent="goTo('erstgespräch')">Erstgespräch</a>
        </li>
        <li class="nav__item">
          <a href="#" class="nav__link" @click.prevent="goTo('schwerpunkte')">Schwerpunkte</a>
        </li>
        <li class="nav__item">
          <a href="#" class="nav__link" @click.prevent="goTo('methode')">Methode</a>
        </li>
        <li class="nav__item">
          <a href="#" class="nav__link" @click.prevent="goTo('kontakt')">Kontakt</a>
        </li>
      </ul>
    </nav>

    <button class="toggle-menu" @click="toggleMenu">
      <menu-icon v-if="!menuActive">Menü</menu-icon>
      <close-menu-icon v-if="menuActive">Menü schließen</close-menu-icon>
    </button>
  </div>
</template>

<script>
import MenuIcon from './svg/MenuIcon'
import CloseMenuIcon from './svg/CloseMenuIcon'

export default {
  name: 'MenuComponent',
  components: {
    MenuIcon,
    CloseMenuIcon,
  },
  data() {
    return {
      menuActive: false,
      menuImgs: [
        { url: require('@/assets/images/illus/menue_960.png'), size: 960 },
        { url: require('@/assets/images/illus/menue_1280.png'), size: 1280 },
        { url: require('@/assets/images/illus/menue_1920.png'), size: 1920 },
      ],
    }
  },
  methods: {
    toggleMenu() {
      this.menuActive = !this.menuActive
    },
    goTo(target) {
      if (this.$route.name === 'home') {
        this.scrollTo(target, false)
      } else {
        this.$router.push({ name: 'home' }).then(() => {
          this.scrollTo(target, false)
        })
      }
      this.toggleMenu()
    },
    scrollTo(target, animation = true) {
      const targetEl = document.getElementById(target)
      if (animation) {
        targetEl.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
      } else {
        const offset = targetEl.getBoundingClientRect().top

        // some browser apply the overall scroll to html and others to body
        document.documentElement.scrollTop += offset
        document.body.scrollTop += offset
      }
    },
    getSrcset(imgs) {
      return imgs.reduce((acc, res) => acc + res.url + ' ' + res.size + 'w,', '')
    },
    getSrc(imgs) {
      return imgs[0].url
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/_mixins.scss';

.menu {
  position: absolute;
  z-index: 1000;

  .nav {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $col-gray-100;
    transform: translateX(100%);
    transition: transform 375ms cubic-bezier(0.4, 0, 0.2, 1);

    @include wider-than(1440px) {
      transition: transform 750ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    &__illu {
      display: none;

      @include wider-than(1440px) {
        display: grid;
        justify-items: center;
        align-items: center;
        position: absolute;
        height: 100%;
        width: 40%;
        right: 50%;
        opacity: 0;
        transform: translate(-5vw, 0);
        transition: opacity 750ms ease-in-out 750ms,
          transform 750ms cubic-bezier(0, 0.5, 0.5, 1) 750ms;
      }

      img {
        height: 80%;
        width: 80%;
        object-fit: contain;
      }
    }

    &__list {
      display: flex;
      height: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transform: translate(0, 10vh);
      transition: opacity 375ms ease-in-out 375ms,
        transform 375ms cubic-bezier(0, 0.5, 0.5, 1) 375ms;

      @include wider-than(1440px) {
        position: absolute;
        width: 40%;
        left: 50%;
        transform: translate(5vw, 0);
        transition: opacity 750ms ease-in-out 750ms,
          transform 750ms cubic-bezier(0, 0.5, 0.5, 1) 750ms;
      }
    }

    &__item {
      margin: 1vh 0;
      padding: 1vh;
      font-size: 4.125vh;
      font-weight: $font-weight-text;
      line-height: $leading-medium;
    }

    &__link {
      position: relative;

      @include hover-underline;

      &::after {
        height: 0.25vh;
      }
    }

    &.active {
      transform: translateX(0);

      .nav__illu,
      .nav__list {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
}

.toggle-menu {
  position: fixed;
  top: 1rem;
  right: 1rem;
  height: 2.5rem;

  @include desktop {
    top: 2rem;
    right: 2rem;
  }
}
</style>
