<template>
  <section class="abschnitt abschnitt--schwerpunkte">
    <h2 class="title fade-in">Schwerpunkte</h2>

    <ul>
      <li class="schwerpunkt fade-in">
        <div class="illu">
          <img
            :srcset="getSrcset(schwerpunkteKinderImgs)"
            :src="getSrc(schwerpunkteKinderImgs)"
            sizes="(min-width: 768px) 20rem, 16rem"
            alt="TherapeutInnen-Sessel und Kindersessel"
          />
        </div>

        <article class="article">
          <h3 class="subtitle">Kinder & Jugendliche</h3>

          <div class="cms" v-html="schwerpunkte_kinder"></div>
        </article>
      </li>

      <li class="schwerpunkt fade-in">
        <div class="illu">
          <img
            :srcset="getSrcset(schwerpunktePaarImgs)"
            :src="getSrc(schwerpunktePaarImgs)"
            sizes="(min-width: 768px) 20rem, 16rem"
            alt="TherapeutInnensessel und Sofa"
          />
        </div>

        <article class="article">
          <h3 class="subtitle">Elternberatung</h3>

          <div class="cms" v-html="schwerpunkte_eltern"></div>
        </article>
      </li>

      <li class="schwerpunkt fade-in">
        <div class="illu">
          <img
            :srcset="getSrcset(schwerpunkteErwachseneImgs)"
            :src="getSrc(schwerpunkteErwachseneImgs)"
            sizes="(min-width: 768px) 20rem, 16rem"
            alt="TherapeutInnensessel und zweiter Sessel"
          />
        </div>

        <article class="article">
          <h3 class="subtitle">Erwachsene</h3>

          <div class="cms" v-html="schwerpunkte_erwachsene"></div>
        </article>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  name: 'AbschnittSchwerpunkte',
  data() {
    return {
      schwerpunkteKinderImgs: [
        { url: require('@/assets/images/illus/schwerpunkte_kinder_960.png'), size: 960 },
        { url: require('@/assets/images/illus/schwerpunkte_kinder_1280.png'), size: 1280 },
        { url: require('@/assets/images/illus/schwerpunkte_kinder_1920.png'), size: 1920 },
      ],
      schwerpunktePaarImgs: [
        { url: require('@/assets/images/illus/schwerpunkte_paar_960.png'), size: 960 },
        { url: require('@/assets/images/illus/schwerpunkte_paar_1280.png'), size: 1280 },
        { url: require('@/assets/images/illus/schwerpunkte_paar_1920.png'), size: 1920 },
      ],
      schwerpunkteErwachseneImgs: [
        { url: require('@/assets/images/illus/schwerpunkte_erwachsene_960.png'), size: 960 },
        { url: require('@/assets/images/illus/schwerpunkte_erwachsene_1280.png'), size: 1280 },
        { url: require('@/assets/images/illus/schwerpunkte_erwachsene_1920.png'), size: 1920 },
      ],
    }
  },
  computed: {
    schwerpunkte_kinder() {
      return this.$store.getters.schwerpunkte_kinder
    },
    schwerpunkte_eltern() {
      return this.$store.getters.schwerpunkte_eltern
    },
    schwerpunkte_erwachsene() {
      return this.$store.getters.schwerpunkte_erwachsene
    },
  },
  methods: {
    getSrcset(imgs) {
      return imgs.reduce((acc, res) => acc + res.url + ' ' + res.size + 'w,', '')
    },
    getSrc(imgs) {
      return imgs[0].url
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/_mixins.scss';

.abschnitt--schwerpunkte {
  width: 100%;
  max-width: 448px;
  margin-left: auto;
  margin-right: auto;

  @include desktop {
    max-width: 1024px;
  }

  .title {
    @include desktop {
      margin-bottom: 4rem;
      text-align: center;
    }
  }

  .schwerpunkt {
    padding: 2rem 0 4rem;

    @include desktop {
      display: grid;
      grid-template-columns: repeat(2, minmax(50%, 50%));
      grid-template-areas: 'illu article';
      padding: 2rem 0;
    }
  }

  .illu {
    position: relative;
    width: 16rem;
    max-width: 50%;
    margin: 0 auto 1rem;
    overflow: hidden;

    &::after {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 50%;
    }

    @include desktop {
      grid-area: illu;
      align-self: center;
      justify-self: center;
      width: calc(100% - 4rem);
      max-width: 20rem;
    }

    img {
      position: absolute;
      width: 100%;
      object-fit: center;
    }
  }

  .article {
    width: 100%;

    @include desktop {
      align-self: start;
    }

    .subtitle {
      text-align: center;

      @include desktop {
        text-align: left;
      }
    }
  }
}
</style>
