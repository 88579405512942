<template>
  <footer class="footer">
    <div class="footer-content">
      <div class="fuchs-frage">
        <router-link :to="{ name: 'der-fuchs' }">
          <img :src="require('@/assets/images/illus/fuchs.png')" alt="fuchs" />
          <p>Was hat es mit dem Fuchs auf sich?</p>
        </router-link>
      </div>

      <p class="name">Sophie Kari</p>

      <div class="kontaktdaten">
        <p class="tel">0650 310 13 43</p>
        <p class="email">praxis@sophiekari.at</p>
      </div>

      <div class="legal">
        <router-link class="impressum" :to="{ name: 'impressum' }">Impressum</router-link>
        &nbsp;|&nbsp;
        <router-link class="datenschutz" :to="{ name: 'datenschutz' }">Datenschutz</router-link>
      </div>

      <div class="copyright">
        <p>Copyright {{ new Date().getFullYear() }}</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent',
}
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/_mixins.scss';

.footer {
  position: relative;
  margin-top: auto;
  font-size: 0.75rem;
  line-height: 1.25;

  &::before {
    content: '';
    position: absolute;
    background: $col-gray-200;
    top: 0;
    bottom: 0;
    left: calc(50% - 50vw);
    right: calc(50% - 50vw);
    z-index: -1;
  }

  @include desktop {
    font-size: 0.875rem;
  }

  .footer-content {
    width: calc(100% - 4rem);
    max-width: 32rem;
    margin: 0 auto;
    padding: 2.5rem 0;

    @include desktop {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-row-gap: 2rem;
      grid-template-areas:
        'fuchs-frage . name'
        'legal copyright kontaktdaten';
      width: calc(100%);
      max-width: 1440px;
    }
  }

  .fuchs-frage {
    text-align: center;

    @include desktop {
      grid-area: fuchs-frage;
      text-align: left;
    }

    a {
      display: inline-flex;
      align-items: center;
      font-weight: $font-weight-title;
      letter-spacing: $tracking-medium;

      img {
        height: 2rem;
        margin-right: 1.5rem;
      }

      p {
        text-align: left;
      }
    }
  }

  .name,
  .kontaktdaten {
    line-height: 1.25;
    text-align: center;

    @include desktop {
      text-align: right;
    }
  }

  .name {
    margin-top: 2rem;
    font-weight: $font-weight-title;

    @include desktop {
      grid-area: name;
      margin-top: 0;
    }
  }

  .kontaktdaten {
    margin-top: 1rem;

    @include desktop {
      grid-area: kontaktdaten;
      align-self: end;
      margin-top: 0;
    }
  }

  .legal {
    margin-top: 2rem;
    text-align: center;
    line-height: 1.25;

    @include desktop {
      grid-area: legal;
      margin: 0;
      align-self: end;
      text-align: left;
    }

    .impressum,
    .datenschutz {
      display: inline-block;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  .copyright {
    text-align: center;
    line-height: 1.25;

    @include desktop {
      grid-area: copyright;
      align-self: end;
      margin-top: 0;
    }
  }
}
</style>
