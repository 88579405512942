<template>
    <svg viewBox="0 0 64 64">
        <title>
            <slot>Close Menu</slot>
        </title>
        <rect x="9.37" y="31" width="45.25" height="2" rx="1" transform="translate(32 -13.25) rotate(45)"/>
        <rect x="9.37" y="31" width="45.25" height="2" rx="1" transform="translate(77.25 32) rotate(135)"/>
    </svg>
</template>

<script>
export default {
    name: 'CloseMenuIcon',
}
</script>

<style lang="scss" scoped>
svg {
    display: block;
    height: 100%;
}
</style>