<template>
  <div
    class="header"
    :class="[{ hidden: headerHidden }, { 'home-top': homeTop }, { transitions: transitions }]"
  >
    <a class="logo" href="#" @click.prevent="goTo('hero')">
      <logo-image class="logo__img"></logo-image>
      <logo-text class="logo__text"></logo-text>
    </a>
  </div>
</template>

<script>
import LogoImage from './svg/LogoImage'
import LogoText from './svg/LogoText'

export default {
  name: 'HeaderComponent',
  components: {
    LogoImage,
    LogoText,
  },
  data() {
    return {
      headerHidden: false,
      homeTop: false,
      transitions: false,
      scrollInterval: null,
    }
  },
  mounted() {
    this.responsiveHeader()
  },
  methods: {
    responsiveHeader() {
      let scrolled = false,
        lastScrollTop = 0,
        scrollTop = 0

      const delta = 5

      const route = this.$route.name

      const header = document.querySelector('.header')
      const headerHeight = header.getBoundingClientRect().height

      window.addEventListener('scroll', () => setScrolledTrue())
      const setScrolledTrue = () => (scrolled = true)

      const setup = () => {
        toggleHeaderHomeTop()
        setTimeout(function () {
          activateTransitions()
        }, 500)
      }

      const activateTransitions = () => (this.transitions = true)

      const runIntervall = () => {
        this.scrollIntervall = setInterval(function () {
          if (scrolled) {
            scrolled = false
            hasScrolled()
          }
        }, 125)
      }

      const hasScrolled = () => {
        // some browser apply the overall scroll to html and others to body
        scrollTop = Math.max(document.documentElement.scrollTop, document.body.scrollTop)

        toggleHeaderVisibility()
        toggleHeaderHomeTop()

        lastScrollTop = scrollTop
      }

      const toggleHeaderVisibility = () => {
        if (Math.abs(lastScrollTop - scrollTop) <= delta) return

        if (scrollTop > lastScrollTop && scrollTop > headerHeight) {
          this.headerHidden = true
        } else if (scrollTop < lastScrollTop) {
          this.headerHidden = false
        }
      }

      const toggleHeaderHomeTop = () => {
        if (route != 'home') return
        if (Math.floor(scrollTop) == 0) {
          this.homeTop = true
        } else {
          this.homeTop = false
        }
      }

      setup()
      runIntervall()
    },
    endResponsiveHeader() {
      clearInterval(this.scrollInterval)
    },
    goTo(target) {
      if (this.$route.name === 'home') {
        this.scrollTo(target, true)
      } else {
        this.$router.push({ name: 'home' }).then(() => {
          this.scrollTo(target, false)
        })
      }
    },
    scrollTo(target, animation = true) {
      const targetEl = document.getElementById(target)
      if (animation) {
        targetEl.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
      } else {
        const offset = targetEl.getBoundingClientRect().top

        // some browser apply the overall scroll to html and others to body
        document.documentElement.scrollTop += offset
        document.body.scrollTop += offset
      }
    },
  },
  beforeDestroy() {
    this.endResponsiveHeader()
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/_mixins.scss';

.header {
  position: fixed;
  width: 100%;
  height: 6.5rem;
  z-index: 500;
  transform: translateY(0);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  @include desktop {
    height: 8.5rem;
    box-shadow: none;
  }

  &::before {
    content: '';
    position: absolute;
    background: $col-white;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 1;
  }

  @include mobile {
    &.hidden {
      transform: translateY(calc(-100% - 16px));
    }
  }

  .logo {
    display: block;
    position: absolute;
    height: 4.5rem;
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);

    @include desktop {
      top: 2rem;
    }

    &__img {
      position: relative;
      transform: scale(1) translateY(0);
      transform-origin: top center;
    }

    &__text {
      position: absolute;
      top: 0;
      height: 100%;
      opacity: 1;
    }
  }

  &.home-top {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0);

    &::before {
      opacity: 0;
    }

    .logo {
      &__img {
        transform: scale(1.375) translateY(8px);

        @include wider-than(512px) {
          transform: scale(1.75) translateY(16px);
        }
      }

      &__text {
        opacity: 0;
      }
    }
  }

  &.transitions {
    transition: opacity 0ms linear, transform 250ms cubic-bezier(0, 0.5, 0.5, 1),
      box-shadow 250ms ease-in-out;

    &::before {
      transition: opacity 250ms ease-in-out;
    }

    @include mobile {
      &.hidden {
        transition: opacity 250ms ease-in-out, transform 500ms cubic-bezier(0, 0, 0.3, 1);
      }
    }

    .logo {
      &__img {
        transition: transform 500ms ease-in-out;
      }

      &__text {
        transition: opacity 500ms ease-in-out;
      }
    }
  }
}
</style>
