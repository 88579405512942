<template>
  <div class="app-outer-wrap">
    <router-view :layout="layout"></router-view>

    <menu-component></menu-component>
  </div>
</template>

<script>
import Layout from './js-modules/Layout'

export default {
  name: 'App',
  data() {
    return {
      layout: {},
    }
  },
  created() {
    this.layout = new Layout(768)
  },
}
</script>
